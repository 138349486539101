<template>
  <div
    class="auth-reg"
    :style="`padding-top: ${isApp ? systemBarHeight : 0}px`"
  >
    <div class="reg-title">
      <v-btn
        small
        class="app-login-button"
        outlined
        color="#eee"
        @click="goLogin"
        @touchstart="zzzMid"
      >
        <v-icon light x-small>fa fa-arrow-left</v-icon>
        返回登录
      </v-btn>
      <div class="reg-brand-title">NOTEVER</div>
      <div class="reg-main-title">用户注册</div>
    </div>
    <v-card elevation="20" class="reg-panel">
      <div class="reg-form">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="regForm.userName"
                  :rules="userNameRules"
                  clear-icon="fas fa-times"
                  label="起一个用户名"
                  autocomplete="off"
                  required
                  clearable
                  class="reg-text"
                  color="#8ca0ff"
                  background-color="#eee"
                  filled
                  rounded
                  dense
                  @click:clear="zzzMid"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="regForm.email"
                  :rules="emailRules"
                  clear-icon="fas fa-times"
                  label="您的email地址"
                  autocomplete="off"
                  required
                  clearable
                  class="reg-text"
                  color="#8ca0ff"
                  background-color="#eee"
                  filled
                  rounded
                  dense
                  @click:clear="zzzMid"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-form ref="smsForm" v-model="phoneValid" lazy-validation>
                  <v-text-field
                    v-model="regForm.phoneNumber"
                    :rules="phoneNumberRules"
                    clear-icon="fas fa-times"
                    label="您的手机号"
                    autocomplete="off"
                    required
                    clearable
                    class="reg-text"
                    color="#8ca0ff"
                    background-color="#eee"
                    filled
                    rounded
                    dense
                    @click:clear="zzzMid"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="7" md="9">
                    <v-text-field
                      v-model="regForm.smsCode"
                      :rules="smsCodeRules"
                      clear-icon="fas fa-times"
                      label="短信验证码"
                      autocomplete="off"
                      required
                      clearable
                      class="reg-text"
                      color="#8ca0ff"
                      background-color="#eee"
                      filled
                      rounded
                      dense
                      @click:clear="zzzMid"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" md="3">
                    <v-btn
                      class="smscode-button"
                      :loading="smsLoading"
                      :disabled="smsLoading || smsWaitSecond !== -1"
                      :color="regButtonColor"
                      @click="sendSms"
                      @touchstart="zzzShort"
                      @touchend="zzzMid"
                    >
                      <span v-if="smsWaitSecond !== -1"
                        >倒计时 {{ smsWaitSecond }}</span
                      >
                      <span v-else>获取验证码</span>
                      <template v-slot:loader>
                        <span class="loading-status"
                          >正在发送
                          <span class="icon-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span></span
                        >
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="regForm.sex"
                  :items="sexSelectItems"
                  :rules="sexRules"
                  item-text="sex"
                  item-value="value"
                  label="您的性别"
                  required
                  filled
                  rounded
                  dense
                  @click="zzzMid"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="regForm.passWord"
                  autocomplete="off"
                  :rules="passWordRules"
                  clear-icon="fas fa-times"
                  :type="showPass ? 'text' : 'passWord'"
                  :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                  label="设置登录密码"
                  @click:append="changeShowPass"
                  required
                  clearable
                  class="reg-text"
                  color="#8ca0ff"
                  background-color="#eee"
                  filled
                  rounded
                  dense
                  @click:clear="zzzMid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-btn
                  class="reg-button"
                  :loading="regLoading"
                  :disabled="regLoading"
                  :color="regButtonColor"
                  @click="doRegister"
                >
                  立刻注册
                  <template v-slot:loader>
                    <span class="loading-status"
                      >正在注册
                      <span class="icon-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span></span
                    >
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";
import $api from "@/api/api";
import { deepClone } from "@/utils/common";

export default {
  name: "regPanel",
  mixins: [buttonVibrate],
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
    }),
  },
  data: () => ({
    regLoading: false,
    regButtonColor: "#4c62cc",
    valid: false,
    phoneValid: false,
    showPass: false,
    smsLoading: false,
    smsWaitSecond: -1,
    smsTimer: null,
    regForm: {
      userName: "",
      email: "",
      phoneNumber: "",
      smsCode: "",
      sex: "",
      passWord: "",
    },
    sexSelectItems: [
      { sex: "男", value: 1 },
      { sex: "女", value: 0 },
      { sex: "其他", value: 2 },
    ],

    userNameRules: [
      (v) => !!v || "请输入用户名",
      (v) => (!!v && v.length >= 4) || "用户名不少于4个字符！",
      (v) => (!!v && v.length <= 20) || "用户名长度不能超过20个字符",
      (v) => /^[A-Za-z0-9]+$/.test(v) || "用户名只能是英文和数字！",
    ],

    emailRules: [
      (v) => !!v || "请输入邮箱",
      (v) => /.+@.+\..+/.test(v) || "请输入正确的邮箱！",
    ],

    sexRules: [(v) => v !== "" || "请选择性别"],

    phoneNumberRules: [
      (v) => !!v || "请输入手机号",
      (v) =>
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          v
        ) || "请输入正确的手机号！",
    ],

    smsCodeRules: [
      (v) => !!v || "请输入短信验证码",
      (v) => /^\d{6}$/.test(v) || "验证码格式错误！",
    ],

    passWordRules: [
      (v) => !!v || "请输入密码",
      (v) => (!!v && v.length >= 4) || "密码不少于6个字符！",
      (v) => (!!v && v.length <= 20) || "密码长度不能超过20个字符",
      (v) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(v) ||
        "密码必须包含大小写字母和数字的组合！",
    ],
  }),
  methods: {
    doRegister() {
      let flag = true;
      if (!this.$refs.form.validate()) {
        flag = false;
      }
      if (!this.$refs.smsForm.validate()) {
        flag = false;
      }
      if (flag) {
        this.regLoading = true;
        let postData = deepClone(this.regForm);
        $api
          .doRegister(postData)
          .then((ret) => {
            let res = ret.data;
            if (res.code === 0) {
              this.$toast.success(res.msg);
              this.$router.replace("/auth/login");
            } else {
              this.$toast.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.regLoading = false;
          });
      } else {
        this.zzzLong();
      }
    },
    sendSms() {
      if (this.$refs.smsForm.validate()) {
        this.smsLoading = true;
        let postData = {
          phoneNumber: this.regForm.phoneNumber,
        };
        $api
          .sendSingleVerifyCode(postData)
          .then((ret) => {
            let res = ret.data;
            if (res.code === 0) {
              this.$toast.success(res.msg);
              this.smsWaitSecond = 30;
              this.smsTimer = setInterval(() => {
                if (this.smsWaitSecond >= 0) {
                  this.smsWaitSecond -= 1;
                } else {
                  clearInterval(this.smsTimer);
                }
              }, 1000);
            } else {
              this.$toast.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.smsLoading = false;
          });
      } else {
        this.zzzLong();
      }
    },
    goLogin() {
      this.$router.push("/auth/login");
    },
    changeShowPass() {
      this.showPass = !this.showPass;
      this.zzzShort();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: 2.85rem !important;
}

::v-deep .v-icon {
  font-size: 1rem;
}

::v-deep .v-label {
  font-size: 0.85rem;
  letter-spacing: 0.15rem;
}

::v-deep .v-input--dense .v-label {
  top: 14.5px;
}

.auth-reg {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;

  .reg-title {
    height: 9rem;
    color: #eeeeee;
    letter-spacing: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .reg-brand-title {
      color: #ffffff;
      margin: 1rem 0 0rem 0;
      align-self: center;
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      font-family: pangmenzhengdaoregular, NotoSansHans， 微软雅黑, sans-serif;
    }

    .reg-main-title {
      color: #ffffff;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0;
    }

    .app-login-button {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.5rem 0.5rem;
      letter-spacing: 0.2rem !important;
      border-radius: 0.5rem !important;
      font-size: 0.8rem;

      i {
        margin-right: 0.2rem;
      }
    }
  }

  .reg-panel {
    width: 100%;
    height: calc(100% - 9rem);
    max-height: calc(100% - 9rem);
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 2rem 0 0 0;

    animation: slogn-motion 0.8s ease;
    -webkit-animation: slogn-motion 0.8s ease; /*Safari and Chrome*/
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
    animation-fill-mode: forwards;
    --webkit-animation-fill-mode: forwards;
    @keyframes slogn-motion {
      from {
        bottom: -18%;
        opacity: 0.5;
      }
      to {
        bottom: 0;
        opacity: 1;
      }
    }

    @-webkit-keyframes slogn-motion /*Safari and Chrome*/ {
      from {
        bottom: -18%;
        opacity: 0.5;
      }
      to {
        bottom: 0;
        opacity: 1;
      }
    }

    .reg-form {
      width: 100%;
      //background-color: blue;
      //height: 50rem;
      margin: 10% 0 0 0;
      padding: 0 1.8rem;

      .reg-text {
        border-radius: 1rem !important;
        margin-bottom: -0.5rem;
      }

      .smscode-button {
        width: 100%;
        height: 2.85rem;
        color: #eeeeee;
        font-size: 0.8rem;
        font-weight: 300;
        border-radius: 0.9rem !important;
        letter-spacing: 0.15rem !important;
        opacity: 0.8;
        //margin-top: 0rem;
        .loading-status {
          letter-spacing: 0.05rem !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icon-loader {
          animation: loader 1s infinite;
          display: flex;
        }
      }

      .reg-button {
        margin-top: 0.6rem;
        width: 100%;
        height: 2.7rem;
        color: #eeeeee;
        font-size: 0.95rem;
        font-weight: 500;
        border-radius: 1rem !important;
        letter-spacing: 0.5rem !important;
      }
    }
  }
}

.loading-status {
  letter-spacing: 0.2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
